<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        If the rate constant
        <stemble-latex content="$\text{k} = 1.35 \, \text{M}^{-2} \, \text{s}^{-1},$" />
        what is the overall order of the rate law? Explain your answer.
      </p>

      <s-textarea v-model="inputs.studentAIResponse" class="mb-n2" outlined></s-textarea>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import STextarea from '@/common/components/STextarea.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'Question487',
  components: {StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAIResponse: null,
      },
    };
  },
};
</script>
